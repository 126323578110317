import React, { useEffect, useState } from 'react';
import { Box, Grid, ListItem, P, Divider, H6 } from '@roadsync/roadsync-ui';
import { Invoice, Fee, Company, User } from '../../types';
import { calculateInvoiceTotalForAllPaymentMethods, isComdataCardServiceOn, isRemoteCheckout } from '../../services/app/invoice';
import { PaymentMethods } from '../../constants/invoice';
import { FeatureFlag } from "../featureFlag/FeatureFlag";
import { getAttributes, isTreatmentOn } from '../../services/app/split';
import { formatDollar } from '../../services/app/formats';
import styles from './RemoteCheckoutGrandTotal.css';
import { StyledComponentProps, withStyles, CircularProgress, Switch } from '@material-ui/core';

interface Props extends StyledComponentProps {
  isCardEnabled?: boolean;
  isComdataCardOn?: boolean;
  isWexEnabled?: boolean;
  comdataApiLocationId?: string;
  isAchPaymentEnabled?: boolean;
  fees?: Fee[];
  invoice: Invoice;
  user?: User;
  company?: Company;
  setShouldEnableAchForInvoice?: (v?: boolean) => Promise<void>;
  shouldEnableAchForInvoice?: boolean;
  isFinalizeScreen?: boolean;
}

const RemoteCheckoutGrandTotals: React.FC<Props> = (props: Props) => {

  const { classes, isCardEnabled, isComdataCardOn, isWexEnabled, comdataApiLocationId, isAchPaymentEnabled,
    fees, invoice, company, user, shouldEnableAchForInvoice, setShouldEnableAchForInvoice, isFinalizeScreen } = props;
  const [cardGrandTotal, setCardGrandTotal] = useState<string>('');
  const [checkGrandTotal, setCheckGrandTotal] = useState<string>('');
  const [fuelCardGrandTotal, setFuelCardGrandTotal] = useState<string>('');
  const [achGrandTotal, setAchGrandTotal] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [showFuelCard, setShowFuelCard] = useState<boolean>(false);
  const showAch = isAchPaymentEnabled;

  const toggleEnableAchForInvoice = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    if (setShouldEnableAchForInvoice) await setShouldEnableAchForInvoice(event.target.checked);
  };

  useEffect(() => {
    const userAttributes = getAttributes(user, company);
    setIsLoading(true);
    setShowFuelCard(!!isComdataCardOn || !!isWexEnabled)

    isTreatmentOn(FeatureFlag.ConvenienceFeeGrid, userAttributes).then((isSplitEnabled) => {
      setShow(isSplitEnabled && isRemoteCheckout(invoice));
    }).catch(() => setShow(false));

    isComdataCardServiceOn(user, company).then((isComdataOn) => {
      setShowFuelCard((!!isComdataOn && !!comdataApiLocationId) || !!isWexEnabled);
    });

    calculateInvoiceTotalForAllPaymentMethods(invoice, fees)
      .then((price) => {
        setCardGrandTotal(price?.invoiceTypes?.[PaymentMethods.CARD.key]?.GrandTotal ?? 'n/a');
        setCheckGrandTotal(price?.invoiceTypes?.[PaymentMethods.CHECK.key]?.GrandTotal ?? 'n/a');
        setFuelCardGrandTotal(price?.invoiceTypes?.[PaymentMethods.FUEL_CARD.key]?.GrandTotal);
        setAchGrandTotal(price?.invoiceTypes?.[PaymentMethods.ACH.key]?.GrandTotal);
      })
      .catch(() => setShow(false))
      .finally(() => setIsLoading(false));

    // Disable this to prevent multiple updates.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCardEnabled, isComdataCardOn, isWexEnabled, comdataApiLocationId, fees, company, user, invoice]);

  if (!show) return <></>;

  return (
    <>
      <Divider component="li" />
      <br />
      <ListItem>
        <Grid container justifyContent="flex-end" direction="row" spacing={2}>
          <Grid item xs={12} sm={12}>
            <Box component="span" id="new-rc-grand-total-label" className={classes?.gridGrandTotalTitle}>Grand Total</Box>
            <P className={classes?.gridGrandTotalWarning}>Grand Total will be calculated when payer selects final method of payment</P>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Grid container direction="row" alignItems="center" justifyContent="flex-end">
              {isCardEnabled &&
                <>
                  <Grid item xs={8}><H6>{PaymentMethods.CARD.display}</H6></Grid>
                  <Grid item xs={4} className={classes?.gridGrandTotalAmount}>
                    <Box textAlign="right" id="remote-checkout-card-grand-total" key="remote-checkout-card-grand-total">
                      {isLoading ? <CircularProgress size={20} /> : formatDollar(cardGrandTotal)}
                    </Box>
                  </Grid>
                </>
              }
              <Grid item xs={8}><H6>{PaymentMethods.CHECK.display}</H6></Grid>
              <Grid item xs={4}>
                <Box textAlign="right" className={classes?.gridGrandTotalAmount}>
                  {isLoading ? <CircularProgress size={20} /> : formatDollar(checkGrandTotal)}
                </Box>
              </Grid>
              {showFuelCard &&
                <>
                  <Grid item xs={8}><H6>{PaymentMethods.FUEL_CARD.display}</H6></Grid>
                  <Grid item xs={4}>
                    <Box textAlign="right" className={classes?.gridGrandTotalAmount}>
                      {isLoading ? <CircularProgress size={20} /> : formatDollar(fuelCardGrandTotal)}
                    </Box>
                  </Grid>
                </>
              }
              {showAch &&
                <>
                  <Grid item xs={8}><H6>{PaymentMethods.ACH_PAYMENT.display}</H6></Grid>
                  <Grid item xs={4}>
                    <Box textAlign="right" className={classes?.gridGrandTotalAmount}>
                      {isLoading ? <CircularProgress size={20} /> : formatDollar(achGrandTotal)}
                    </Box>
                  </Grid>
                </>
              }
              {showAch &&
                <Grid item xs={12}>
                  <Switch color="primary"
                    checked={shouldEnableAchForInvoice}
                    value={shouldEnableAchForInvoice}
                    id="toggle-enable-ach-for-invoice"
                    onChange={toggleEnableAchForInvoice}
                    disabled={isFinalizeScreen}
                  />
                  <Box component='span'>Enable ACH for this invoice</Box>
                </Grid>
              }
            </Grid>
          </Grid>
        </Grid>
      </ListItem>
    </>
  );
}

export default withStyles(styles)(RemoteCheckoutGrandTotals);
