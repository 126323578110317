export enum FeatureFlag {
    // InvoiceStatusFilter = 'invoice_status_filter', // still exists in split
    WootricSurvey = 'wootric_survey',
    SentToMattDamonV2 = 'send_to_matt_damon_v2',
    FuelCards = 'wex-fuel-cards',
    SurveyAcknowledgementAcceptsTermsAndConditions = 'survey_acknowledgement_accepts_terms_and_condition',
    DriversReceiptExperiment = 'driver-receipts-experiment',
    RemoteCheckoutV2 = 'remote-checkout-v2',
    RemoteCheckoutFlutter = 'RC-flutter',
    WorkOrderPublicAuthorizationFlutter = 'WO-flutter',
    ComdataCheckApi = 'comdata-check-api',
    ComdataCardApi = 'comdata-card-api',
    DeviceManagement = 'device-order',
    CsChatWidgetSwitch = 'cs-chat-widget-switch',
    VGSFuelCardTokenization = 'VGS-fuel-card-tokenization',
    Payouts = 'instant-payout',
    AutoPayout = 'auto-instant-payout',
    ConvenienceFeeGrid = 'conv-fee-grid',
    CreditCardSignatureEnabled = 'credit-card-signature-enabled',
    FleetChecksIsDisabled = 'fleet-check-disabled',
    RefundKillSwitch = 'refund-kill-switch',
    AlternateUILabels = 'alternate-ui-labels',
    RefreshAccessToken = 'refresh-access-token',
    InstantPayoutPatchFundingSource = 'instant-payout-patch-funding-source',
    CognitoLogin = 'cognito-auth',
    AzureSSO = 'azure-sso-rsc-3778',
}

export function getAllFeatureFlags(): string[] {
    return Object.keys(FeatureFlag).map(k => FeatureFlag[k]);
}
